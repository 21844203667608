import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Link, MenuList } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { keyframes } from "@emotion/react";

import { PRODUCT_CATEGORY } from "../../app/config";
import "./MenuCategory.css";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    animation: `${fadeIn} 0.5s ease-out`,
  },
}));

const styles = {
  typo: {
    display: "flex",
    alignItems: "center",
    "&: hover": {
      cursor: "pointer",
    },
    justifyContent: "space-between",
  },

  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },

  menuItem: {
    display: "flex",
    alignItems: "center",
  },

  menuBox: {
    display: "flex",
    minWidth: 110,
    alignItems: " center",
  },
};

export default function MenuCategory() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value, category) => {
    setAnchorEl(null);

    if (category !== "backdropClick") navigate(`/category/${category}`);
  };

  return (
    <Box>
      <MenuList>
        <MenuItem
          onClick={handleClick}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={styles.menuBox}>
            <svg
              className="icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64H80V384H304V64z" />
            </svg>
            Điện Thoại
          </Box>
          <KeyboardArrowRightIcon />
        </MenuItem>

        <StyledMenu
          anchorOrigin={styles.anchorOrigin}
          transformOrigin={styles.transformOrigin}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {PRODUCT_CATEGORY.map((e) => (
            <MenuItem
              component={Link}
              key={e.category}
              onClick={() => handleClose(e.value, e.category)}
              disableRipple
            >
              {e.category}
            </MenuItem>
          ))}
        </StyledMenu>

        <MenuItem
          component={Link}
          target="_blank"
          href="https://www.facebook.com/TaoXanhStores"
          sx={styles.menuItem}
        >
          <svg
            className="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
          </svg>
          Facebook Fanpage
        </MenuItem>

        <MenuItem
          component={Link}
          target="_blank"
          href="https://www.tiktok.com/@taoxanhstores?_t=8iWuHk5Yykj&_r=1"
          sx={styles.menuItem}
        >
          <svg
            className="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
          </svg>
          Tiktok Chanel
        </MenuItem>
      </MenuList>
    </Box>
  );
}
